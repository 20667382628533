@import '~semantic-ui-css/semantic.css';
@import '~leaflet/dist/leaflet.css';
@import '~tachyons/css/tachyons.css';
@import '~react-semantic-toasts/styles/react-semantic-alert.css';
@import '~leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';

body {
  margin: 0;
  padding: 0;
}

i.help.grey.icon {
  color: #00B050 !important;
}

.hyviate-logo {
  background-image: url('https://hyviate.com/wp-content/uploads/2022/09/cropped-logo_roboto_green_400_100.png');
  height: 100px;
  width: 100px;
  scale: ;
  background-position: center;
  background-repeat: no-repeat;
}

.valhalla-logo {
  background-image: url('images/valhalla.png');
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
}

.ui.checkbox label,
.ui.checkbox + label,
.custom-label {
  font-size: 0.85em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #00B050 !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #00B050 !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #00B050 !important;
}

.ui.form .inline.fields {
  margin: 0em 0em 0em;
}

.extra-marker i {
  font-style: normal;
  font-size: 12px;
  font-weight: bold;
}

.benchmark-green {
  color: #00B050;
}

.benchmark-red {
  color: #b00000;
}